import React from 'react'
import GlegooBold from "../../assets/fonts/Glegoo/Glegoo-Bold.ttf";
import GlegooRegular from "../../assets/fonts/Glegoo/Glegoo-Regular.ttf";
import GilroyBold from "../../assets/fonts/gilroy/Gilroy-Bold.ttf";
import GilroyMedium from "../../assets/fonts/gilroy/Gilroy-Medium.ttf";
import GilroyRegular from "../../assets/fonts/gilroy/Gilroy-Regular.ttf";
import GilroySemiBold from "../../assets/fonts/gilroy/Gilroy-SemiBold.ttf";

import {
    Document,
    Page,
    View,
    Text,
    Image,
    Font,
    StyleSheet,
    PDFViewer
} from "@react-pdf/renderer";
import bharat from "../../assets/NewImage/bharat.png"
import bharatBlack from "../../assets/NewImage/bharatBlack.png"
import logo from "../../assets/NewImage/logo.png"
import qrcode from "../../assets/NewImage/qrcode.png"
import sign2 from "../../assets/NewImage/sign2.png"
import sign3 from "../../assets/NewImage/sign3.png"
import bgImage from "../../assets/NewImage/bgImage.png"
import leftArrow from "../../assets/images/leftArrow.png";
import rightArrow from "../../assets/images/rightArrow.png";
// import sigen1 from "../../assets/images/sigen1.png";
import Footer from '../footer/Footer';
import moment from 'moment';


Font.register({
    family: "Glegoo-Regular",
    src: GlegooRegular,
});

Font.register({
    family: "Glegoo-Bold",
    src: GlegooBold,
});
Font.register({
    family: "Gilroy-Regular",
    src: GilroyRegular,
});
Font.register({
    family: "Gilroy-Medium",
    src: GilroyMedium,
});
Font.register({
    family: "Gilroy-Bold",
    src: GilroyBold,
});
Font.register({
    family: "Gilroy-SemiBold",
    src: GilroySemiBold,
});

const styles = StyleSheet.create({
    backgroundImage: {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        height: '380.882px',
        width: '389px'
    }, tabelRecord: {
        flexDirection: "row",
        borderTopWidth: 0.5,
        borderColor: "#050340",
    },
    tabelCellView: {
        // flex: 1,
        width: "20%",
        justifyContent: "center",
        alignItems: "center",
        borderRightWidth: 0.5,
        borderColor: "#050340",
        textAlign: "center",
        padding: 6,
    },
    tabelCellCombineView: {
        flex: 1,
        borderRightWidth: 0.5,
        borderColor: "#050340",
        textAlign: "center",
        padding: 6,
    },
    tabelCellCombineLastView: {
        flex: 1,
        borderColor: "#050340",
        textAlign: "center",
        padding: 6,
    },
    tabelCellCobineView: {
        borderRightWidth: 0.5,
        borderColor: "#050340",
        textAlign: "center",
        width: "40%",
    },
    tabelCellLastView: {
        flex: 1,
        textAlign: "center",
        padding: 6,
        justifyContent: "center",
        alignItems: "center",
    },
});

const CertificateGradeBS13 =  (props) => {
    const propsData = props.propsData;
    return (
        <>
            <View
                style={{
                    border: "1px double #050340",
                    margin: "10px"
                }}
            >
                <View
                    style={{
                        border: "1px double #050340",
                        margin: "4px",
                        padding: "10px 0 0 0",
                        height: '100%'
                    }}
                >

                    <View style={styles.backgroundImage}>
                        <Image src={bgImage} style={styles.backgroundImage} />
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0px 15px"

                        }}
                    >
                        <View>
                            <Image src={bharatBlack} alt="bharat"
                                style={{
                                    width: '94px',
                                    height: "107px"
                                }}
                            />
                            <Text
                                style={{
                                    fontSize: '10px',
                                    textAlign: 'center',
                                    marginTop: "5px",
                                    fontFamily: "Gilroy-Bold",
                                    color: "#2B2A28"
                                }}
                            >Certi. No : TC - 11481</Text>
                        </View>
                        <View
                            style={{
                                width: '229',
                                height: "76px"
                            }}
                        >
                            <Image src={logo} alt="logo" />
                        </View>
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            alignItems: 'center',
                            color: '#964392',
                            marginTop: '20px',
                            padding: "0px 15px"

                        }}
                    >
                        <View>
                            <Text
                                style={{
                                    fontSize: '24px',
                                    fontFamily: 'Glegoo-Bold',
                                }}
                            >Certificate of Conformity</Text>
                        </View>
                        <View style={{ width: "20%", textAlign: "right" }}>
                            <Text
                                style={{
                                    color: '#964392',
                                    fontFamily: "Gilroy-Bold",
                                    fontSize: "12px",
                                }}
                            >
                                BS 1377 - Part-9
                            </Text>
                            <Text
                                style={{
                                    color: '#964392',
                                    fontFamily: "Gilroy-Bold",
                                    fontSize: "12px",
                                }}
                            >
                                Density Sand
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            borderTop: '1px solid #964392',
                            margin: "0px 15px"
                        }}
                    >
                    </View>
                    <View
                        style={{
                            width: '26%',
                            border: '1.3px solid #964392',
                            backgroundColor: '#964392',
                            margin: "-1px 15px 0px auto"
                        }}
                    >
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            width: "100%",
                            justifyContent: 'space-between',
                            flexDirection: "row",
                            marginTop: '20px',
                            fontSize: '8px',
                            color: '#2B2A28',
                            padding: "0px 15px"

                        }}
                    >
                        <View
                            style={{ width: '100%' }}
                        >
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    ULR No. :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                       {propsData && propsData.ulr_number && propsData.ulr_number}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Test Report No. :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    {propsData &&
                                        propsData.batch_number &&
                                        propsData.batch_number}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Tested For :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    Reema Geotech {propsData &&
                                        propsData.tested_for &&
                                        `(${propsData.tested_for})`}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Batch No. :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                      B{propsData &&
                                        propsData.batch_count &&
                                        propsData.batch_count}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Sample Req. Slip No. :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                        {propsData &&
                                        propsData.sample_request_slip_number &&
                                        propsData.sample_request_slip_number}
                               
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Sample Weight :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    1000gm
                                </Text>
                            </View>
                        </View>
                        <View style={{ width: '100%' }}
                        >
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Lab Temp :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    26 °C  70% rh
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Sample Received Date :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                     {propsData &&
                                        propsData.sample_received_date &&
                                        moment(propsData.sample_received_date).format(
                                            "DD MMM YYYY"
                                        )}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Material Type :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    Quartzite
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Sample Detail :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    Quartz Powder
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Date of Testing :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                        {propsData &&
                                        propsData.created_at &&
                                        moment(propsData.created_at).format("DD MMM YYYY")}
                                </Text>
                            </View>
                        </View>

                    </View>
                    <View style={{ border: "0.5px solid #050340", marginTop: 10, marginLeft: '15px', marginRight: '15px' }}>
                        <Text
                            style={{
                                fontSize: "10px",
                                textAlign: "center",
                                padding: 6,
                                fontFamily: "Gilroy-Bold"
                            }}
                        >
                            Test Result
                        </Text>
                        <View style={styles.tabelRecord}>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>Parameter</Text>
                            </View>
                            <View style={styles.tabelCellCobineView}>
                                <Text style={{ fontSize: "8px", padding: 6 }}>Particle Size Analysis</Text>
                                <View style={styles.tabelRecord}>
                                    <View style={styles.tabelCellCombineView}>
                                        <Text style={{ fontSize: "8px" }}>% Pass (0.600 mm)</Text>
                                    </View>
                                    <View style={styles.tabelCellCombineLastView}>
                                        <Text style={{ fontSize: "8px" }}>% Retain (0.063 mm)</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.tabelCellLastView}>
                                <Text style={{ fontSize: "8px" }}>Loose Bulk Density ( KG / Liter )</Text>
                            </View>
                        </View>
                        <View style={styles.tabelRecord}>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>Acceptance</Text>
                            </View>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>100</Text>
                            </View>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>0 ( Fixed Value )</Text>
                            </View>
                        </View>
                        <View style={styles.tabelRecord}>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>Test Result</Text>
                            </View>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>
                                {propsData &&
                                        propsData.pass_through &&
                                        propsData.pass_through}
                               
                                </Text>
                            </View>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>
                                {propsData &&
                                        propsData.retain_on &&
                                        propsData.retain_on}
                                </Text>
                            </View>
                            <View style={styles.tabelCellLastView}>
                                <Text style={{ fontSize: "8px" }}>
                                {propsData &&
                                        propsData.loose_bulk_density &&
                                        propsData.loose_bulk_density}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.tabelRecord}>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>Test Method</Text>
                            </View>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>IS 2386 Part I</Text>
                            </View>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>IS 2386 Part I</Text>
                            </View>
                            <View style={styles.tabelCellLastView}>
                                <Text style={{ fontSize: "8px" }}>IS 2386 Part-III</Text>
                            </View>
                        </View>
                        <View style={styles.tabelRecord}>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>Specification</Text>
                            </View>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>IS 2720 Part-28</Text>
                            </View>
                            <View style={styles.tabelCellView}>
                                <Text style={{ fontSize: "8px" }}>IS 2720 Part-28</Text>
                            </View>
                            <View style={styles.tabelCellLastView}>
                                <Text style={{ fontSize: "8px" }}>-</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{
                        color: '#2B2A28',
                        fontSize: '8px',
                        marginTop: '13px',
                        padding: '0px 15px'
                    }}>
                        <Text style={{
                            fontFamily: 'Gilroy-Regular'
                        }}>
                            * Remarks :
                        </Text>
                        <View style={{
                            fontFamily: 'Gilroy-Bold',
                            padding: '4px 0px 0px 5px',
                            display: 'flex',
                            rowGap: '3px'
                        }}>
                            <Text>• The Sample Confirming to IS 2720 Part 28</Text>
                            <Text>• Test Sieves Confirming to IS 460 Part I</Text>
                            <Text>• Code Name = IS 2720 Part 28</Text>
                        </View>
                    </View>

                    <Footer />
                </View>
            </View>
        </>
    )
}

export default CertificateGradeBS13