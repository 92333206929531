import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import CommanCertificate from "./component/CommanCertificate";
import Home from "./component/Home";
import PrintGatePass from "./component/certificate/PrintGatePass";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/view-certificate/:batchNumber"
          element={<CommanCertificate />}
        />
        {/* <Route path='/print-gatepass' element={<PrintGatePass />} /> */}
      </Routes>
    </BrowserRouter>

    //  <PDFViewer style={{ width: "1500px", height: "900px" }}>
    //   <CommanCertificate />
    // </PDFViewer>
  );
}

export default App;
