// const mode = "DEVLOPMENT"; 
const mode = "PRODUCTION";  

const BASE_URL =
  mode === "PRODUCTION"
    ? "https://api.pinalsand.com"
    : "https://api.pinalsand.vytech.co";

module.exports = {
  BASE_URL,
};
