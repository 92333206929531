import React from 'react'
import GlegooBold from "../../assets/fonts/Glegoo/Glegoo-Bold.ttf";
import GlegooRegular from "../../assets/fonts/Glegoo/Glegoo-Regular.ttf";
import GilroyBold from "../../assets/fonts/gilroy/Gilroy-Bold.ttf";
import GilroyMedium from "../../assets/fonts/gilroy/Gilroy-Medium.ttf";
import GilroyRegular from "../../assets/fonts/gilroy/Gilroy-Regular.ttf";
import GilroySemiBold from "../../assets/fonts/gilroy/Gilroy-SemiBold.ttf";

import {
    Document,
    Page,
    View,
    Text,
    Image,
    Font,
    StyleSheet,
    PDFViewer
} from "@react-pdf/renderer";
import bharat from "../../assets/NewImage/bharat.png"
import bharatBlack from "../../assets/NewImage/bharatBlack.png"
import logo from "../../assets/NewImage/logo.png"
import qrcode from "../../assets/NewImage/qrcode.png"
import sign2 from "../../assets/NewImage/sign2.png"
import sign3 from "../../assets/NewImage/sign3.png"
import bgImage from "../../assets/NewImage/bgImage.png"
import leftArrow from "../../assets/images/leftArrow.png";
import rightArrow from "../../assets/images/rightArrow.png";
// import sigen1 from "../../assets/images/sigen1.png";
import Footer from '../footer/Footer';
import moment from 'moment';


Font.register({
    family: "Glegoo-Regular",
    src: GlegooRegular,
});

Font.register({
    family: "Glegoo-Bold",
    src: GlegooBold,
});
Font.register({
    family: "Gilroy-Regular",
    src: GilroyRegular,
});
Font.register({
    family: "Gilroy-Medium",
    src: GilroyMedium,
});
Font.register({
    family: "Gilroy-Bold",
    src: GilroyBold,
});
Font.register({
    family: "Gilroy-SemiBold",
    src: GilroySemiBold,
});

const styles = StyleSheet.create({
    backgroundImage: {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        height: '380.882px',
        width: '389px'
    },
    mainTableLayout: {
        display: "flex",
        flexDirection: "row",
        fontSize: '8px'
    },
    tableCol: {
        width: "27%",
        textAlign: "center",
        borderRightWidth: 0.5,
        borderColor: "#050340",
    },
    tableLastCol: {
        width: '19%',
        textAlign: "center",
    },
    subCol: {
        padding: "6 0",
        borderBottomWidth: 0.5,
        borderColor: "#050340",
    },
    LastSubCol: {
        padding: "6 0",
    },
    lastbox: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    }
});

const CertificateGradeASTMd = (props) => {
    const propsData = props.propsData;
    return (
        <>
            <View
                style={{
                    border: "1px double #050340",
                    margin: "10px"
                }}
            >
                <View
                    style={{
                        border: "1px double #050340",
                        margin: "4px",
                        padding: "10px 0 0 0",
                        height: '100%'
                    }}
                >

                    <View style={styles.backgroundImage}>
                        <Image src={bgImage} style={styles.backgroundImage} />
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0px 15px"

                        }}
                    >
                        <View>
                            <Image src={bharatBlack} alt="bharat"
                                style={{
                                    width: '94px',
                                    height: "107px"
                                }}
                            />
                            <Text
                                style={{
                                    fontSize: '10px',
                                    textAlign: 'center',
                                    marginTop: "5px",
                                    fontFamily: "Gilroy-Bold",
                                    color: "#2B2A28"
                                }}
                            >Certi. No : TC - 11481</Text>
                        </View>
                        <View
                            style={{
                                width: '229',
                                height: "76px"
                            }}
                        >
                            <Image src={logo} alt="logo" />
                        </View>
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            alignItems: 'center',
                            color: '#C67700',
                            marginTop: '20px',
                            padding: "0px 15px"

                        }}
                    >
                        <View>
                            <Text
                                style={{
                                    fontSize: '24px',
                                    fontFamily: 'Glegoo-Bold',
                                }}
                            >Certificate of Conformity</Text>
                        </View>
                        <View style={{ width: "30%", textAlign: "right" }}>
                            <Text
                                style={{
                                    color: '#C67700',
                                    fontFamily: "Gilroy-Bold",
                                    fontSize: "12px",
                                }}
                            >
                                ASTM D1556/D1556M - 15E1
                            </Text>
                            <Text
                                style={{
                                    color: '#C67700',
                                    fontFamily: "Gilroy-Bold",
                                    fontSize: "12px",
                                }}
                            >
                                ( Density SAND )
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            borderTop: '1px solid #C67700',
                            margin: "0px 15px"
                        }}
                    >
                    </View>
                    <View
                        style={{
                            width: '26%',
                            border: '1.3px solid #C67700',
                            backgroundColor: '#C67700',
                            margin: "-1px 15px 0px auto"
                        }}
                    >
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            width: "100%",
                            justifyContent: 'space-between',
                            flexDirection: "row",
                            marginTop: '30px',
                            fontSize: '8px',
                            color: '#2B2A28',
                            padding: "0px 15px"

                        }}
                    >
                        <View
                            style={{ width: '100%' }}
                        >
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    ULR No. :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    {propsData && propsData.ulr_number && propsData.ulr_number}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Test Report No. :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    {propsData &&
                                        propsData.batch_number &&
                                        propsData.batch_number}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Tested For :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                     Reema Geotech {propsData &&
                                        propsData.tested_for &&
                                        `(${propsData.tested_for})`}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Batch No. :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    B{propsData &&
                                        propsData.batch_count &&
                                        propsData.batch_count}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Sample Req. Slip No. :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    {propsData &&
                                        propsData.sample_request_slip_number &&
                                        propsData.sample_request_slip_number}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Sample Weight :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    1000gm
                                </Text>
                            </View>
                        </View>
                        <View style={{ width: '100%' }}
                        >
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Lab Temp :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    26 °C  70% rh
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Sample Received Date :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    {propsData &&
                                        propsData.sample_received_date &&
                                        moment(propsData.sample_received_date).format(
                                            "DD MMM YYYY"
                                        )}
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Material Type :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    Quartzite
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Sample Detail :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    Quartz Powder
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    marginTop: '2px',
                                }}
                            >
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Medium"
                                    }}>
                                    Date of Testing :
                                </Text>
                                <Text
                                    style={{
                                        fontFamily: "Gilroy-Bold",
                                        marginLeft: '2.5px'
                                    }}>
                                    {propsData &&
                                        propsData.created_at &&
                                        moment(propsData.created_at).format("DD MMM YYYY")}
                                </Text>
                            </View>
                        </View>

                    </View>
                    <View style={{ border: "0.5px solid #050340", marginTop: 10, marginLeft: '15px', marginRight: '15px' }}>
                        <Text
                            style={{
                                fontSize: "10px",
                                textAlign: "center",
                                padding: "6 0 4 0",
                                fontFamily: "Gilroy-Bold"
                            }}
                        >
                            Test Result
                        </Text>
                        <Text
                            style={{
                                fontSize: "8px",
                                textAlign: "center",
                                padding: "8 0 4 6",
                                fontFamily: "Gilroy-Bold",
                                borderTop: "0.5px solid #050340",
                                borderBottom: "0.5px solid #050340",
                            }}
                        >
                            1. Particle Size Distribution
                        </Text>
                        <View style={styles.mainTableLayout}>
                            <View style={styles.tableCol}>
                                <View style={styles.subCol}><Text>Sieve Size (Mesh)</Text></View>
                                <View style={styles.subCol}><Text>#10</Text></View>
                                <View style={styles.LastSubCol}><Text>#60</Text></View>
                            </View>
                            <View style={styles.tableCol}>
                                <View style={styles.subCol}><Text>Acceptance Criterial (% Pass)</Text></View>
                                <View style={styles.subCol}><Text>100%</Text></View>
                                <View style={styles.LastSubCol}><Text>0-3%</Text></View>

                            </View>
                            <View style={styles.tableCol}>
                                <View style={styles.subCol}><Text>Test Result (% Pass)</Text></View>
                                <View style={styles.subCol}><Text>
                                    {propsData &&
                                        propsData.ss_10 &&
                                        propsData.ss_10
                                    }
                                </Text></View>
                                <View style={styles.LastSubCol}><Text>
                                {propsData &&
                                        propsData.ss_60 &&
                                        propsData.ss_60
                                    }
                                    </Text></View>
                            </View>
                            <View style={styles.tableLastCol}>
                                <View style={styles.subCol}>
                                    <Text>Test Method</Text>
                                </View>
                                <View style={styles.lastbox}>
                                    <Text>ASTM C - 136</Text>
                                </View>
                            </View>
                        </View>

                    </View>
                    <View style={{
                        color: '#2B2A28',
                        fontSize: '8px',
                        marginTop: '13px',
                        padding: '0px 15px'
                    }}>
                        <Text style={{
                            fontFamily: 'Gilroy-Regular'
                        }}>
                            * Remarks :
                        </Text>
                        <View style={{
                            fontFamily: 'Gilroy-Bold',
                            padding: '4px 0px 0px 5px',
                            display: 'flex',
                            rowGap: '3px'
                        }}>
                            <Text>• Test Sieves Confirming to ASTM E -11 : 2022.</Text>
                            <Text>• Testing in according to ASTM D1556/D1556M - 15E1 in all respect.</Text>
                        </View>
                    </View>

                    <Footer />
                </View>
            </View>
        </>
    )
}

export default CertificateGradeASTMd